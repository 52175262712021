
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500&family=Poppins:ital,wght@0,300;0,400;1,300&display=swap');
body{
  font-family: 'Dancing Script', cursive;
  font-family: 'Poppins', sans-serif;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.circle{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle h2{
  margin-left: 10px;
}