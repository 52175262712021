.banner{
    background-color:lightblue;
    background-image: url("https://tse1.mm.bing.net/th?id=OIP.5NIjfD9xmWiJ4d2KdLoiGQHaGY&pid=Api&P=0");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 600px;
    color:#fff;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 100px;
    justify-items: center;
    align-items: center;
    
}
.cust_btn{
    padding: 10px 20px;
    border: none;
    outline: none;
    background-color: #2874f0;
    color: #fff;
    width: 150px;
    letter-spacing: 1px;
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;
}
.alg{
    justify-content: center;
    align-items: center;
}
.measure{
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    background-image: url("https://4.bp.blogspot.com/-VudENTobUmE/Tzqw5aJ4GTI/AAAAAAAAJw4/hbHveyDmQKg/s1600/kids+bedroom+furniture+designs.+(2).jpg");
}
.measure p{
    color: #fff;
    font-size: larger;
    font-weight: 400;
    margin-top: 20px;
}
.cust_btn{
    margin-top: 200px;
}
@media (max-width:768px){
    .banner{
        
        background-size: cover;
        width: 100%;
        height: 200px;
        color:#fff;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 30px;
        justify-items: center;
        align-items: center;
        
    }
    .measure{
        background-size: cover;
        background-image: url("https://4.bp.blogspot.com/-VudENTobUmE/Tzqw5aJ4GTI/AAAAAAAAJw4/hbHveyDmQKg/s1600/kids+bedroom+furniture+designs.+(2).jpg");
        width: 100%;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        height: 400px;
        
        justify-items: center;
        align-items: center;
    }
    .measure p{
        color: #000;
        font-size: larger;
        font-weight: 200;
        
    }
    .cust_btn{
        margin-top: 10px;
    }
    .furn{
        justify-content:center;
    }
}