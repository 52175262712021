.new_nav{
    width: 100%;
    height: 50px;
    background-color: #11d6bc;
   
    
}

.new_nav .nav_data{
    display: flex;
    justify-content:space-between;

}

.new_nav .nav_data .left_data{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;
}
p{
    margin-top:15px ;
}

.new_nav .nav_data .left_data p{
    color: #090909;
    font-weight: 500;
    font-size: 15px;
}

/* media query */
@media (max-width:808px){
    .new_nav{
       background-color: aquamarine;
    }
}
