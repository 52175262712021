.service{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 20px;
}
.treebtnt{
  padding: 9px 15px;
    border: none;
    outline: none;
    background-color: #2874f0;
    color: #fff;
    width: 130px;
    letter-spacing: 1px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}
.treebtn{
    padding: 9px 15px;
    border: none;
    outline: none;
    background-color: #2874f0;
    color: #fff;
    width: 100px;
    letter-spacing: 1px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;


}
.imgtree{
    width: 300px;
  height: 350px;
  margin-top: 4px;
}
.treelay{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 20px;
    
  }

.leftservice h3{
color: #444;
font-weight: 500;
font-size: 19px;}
.treecomp{
    display: grid;
    justify-items: center;
    background-color: antiquewhite;
  }
  @media (max-width:1024px){
    .treelay{
        display: flex;
        flex-wrap: wrap;
        
        
      }
  
      .imgtree{
        width: 150px;
      height: 250px;
      margin-top: 4px;
    }
   

.treebtnt{
  padding: 3px 5px;
    width: 100px;
    letter-spacing: 1px;
    font-size:15px;
    border-radius: 4px;
    cursor: pointer;


}

  }

