.banner_img{
    width: 100vw;
    height: 280px;
}

.carasousel{
    margin-top: 10;
}


@media (max-width:768px){
    .banner_img{
        object-fit: cover;
        height: 180px;
    }
}