.Card_items{
  /* padding: 10px 20px; */
  width: 350px;
  margin:10px auto;
  background-color: #fff;
  border-radius: 4px;

}

.Card_items{
  display: grid;
  
}
.dispalytv{
  display: flex;
  flex-wrap: wrap;
}














.view_btn{
  padding: 9px 15px;
  border: none;
  outline: none;
  background-color: #2874f0;
  color: #fff;
  width: 100px;
  letter-spacing: 1px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}



.Card_items img{
  width: 300px;
  height: 350px;
  margin-top: 4px;
}
.Card{
  background-color: #444;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}
.Card_items{
  margin-top: 10px;
  padding: 10px;
  display: grid;
  grid-row: auto;
  align-items: center;
  cursor: pointer;
}
.Card_name {
  color: #fcfcfceb;
  font-size: 20px;
  font-weight: 400;
}
.Card_explore{
  color: white;
}





.Card_items p{
  margin-top: 2px;
}


@media (max-width:768px){
  .slide_part .right_slide{
   display: none;
  }
  
  .slide_part .left_slide{
      width: 100%;
  }

  .center_img img{
      object-fit: cover;
      height: 120px;
  }
  .Card_section .Card_items img{
    width: 100px;
    height: 150px;
    margin-top: 4px;
  }
  .Card_name {
    color: #fcfcfceb;
    font-size: 20px;
    font-weight: 40;
  }
  .Card_explore{
    color: white;
    font-size: 15px;
    font-weight: 20;
  }
  .dispalytv{
    display: grid;
    
  }

}