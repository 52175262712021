.header{
    background-color: #3077d4;
    width:100%;
    height: 0px;
    position: fixed;
    top: 0;
    z-index:2;
    width: 100%;
}

header nav{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 4;
}

header nav .navlogo img{
    width: 130px;
    margin-top: 20px;
}
 .navlogo {
    text-decoration: none;
    color:coral;
     
}

header nav .left{
    display: flex;
    justify-content:start;
    align-items: center;
    margin-left: 20px;
    /* border: 2px solid red; */
    flex: .7;
}



header nav .left input{
    width:400px;
    height: 38px;
    border: none;
    outline: none;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    font-size: 14px;
    padding-left: 10px;
}
#searchtext{
    border-color: #000;
    border-style:solid
}


header nav .left .search_icon{
    
    height: 48px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
    background-color: darksalmon;
    
}

header nav .nav_searchbaar{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* header nav .left input:focus{
    border: 2px solid #febd69;
} */

header nav .right{
    display: flex;
    justify-content:center;
    align-items: center;
    /* border: 2px solid red; */
    flex: .3;
}

header nav .right .nav_btn a{
    color: #201f1f;
    font-weight: 500;
    text-decoration: none;
    margin-right: 2px;
   
    border-radius: 2px;
    padding: 8px;
}



header nav .right .nav_btn a:hover{
    border: 1px solid rgb(16, 15, 15);
}

header nav .right .cart_btn{
    display: flex;
    margin-left: 22px;
    cursor: pointer;
    margin-top: 2px;
    padding: 8px;
    border-radius: 2px;
  
    align-items: center;
    justify-content: space-between;
    margin-right: 3px;
}

header nav .right .cart_btn:hover{
    border: 1px solid rgb(14, 13, 13);
}

header nav .right .cart_btn #icon{
    margin-right: 10px;
    color: #ba2828;
    margin-top: 2px;
    size: 5px;
}




header nav .right .cart_btn p{
    color: #131313;
    font-weight: 500;
    font-size: 16px;
    margin-top: 2px;
    margin-right: 10px;
}

.right a{
    text-decoration: none;
}
.right .nav_btn p{
    
    align-items: center;
    margin-left: -50px;
    margin-top: -20px;
    
}

header nav .avtar{
    cursor: pointer;
}
header nav .avtar2{
    cursor: pointer;
    background-color: #1365cf;
}

header .hamburgur{
    display: none;
}

header .extrasearch{
    position: absolute;
    background-color: #fff;
    color: #000;
    top: 50px;
    width: 440px;
}

header .extrasearch a{
    text-decoration: none;
    color: #000;
}

/* drawer sty */
header nav .drawer{
    width: 250px;
    background-color: red;
}




@media (max-width:768px){

    header nav .left input{
       display: none;
    }
    
    header nav .left .search_icon{
        /* color: #fff;
        font-size: 18px;
        background-color: #131921; */
        display: none;
    }
   
}