.home_section .Card_section{
  /* padding: 10px 20px; */
  width: 98%;
  margin:10px auto;
  background-color: #fff;
  border-radius: 4px;
}

.home_section .slide_part{
  display: flex;
  align-items: center;
}

.slide_part .left_slide{
  width: 80%;
}

.slide_part .right_slide{
  width: 60%;
  height:60px;
  display: flex;
  flex-direction: column;
  justify-content:space-evenly;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
}
.carousel-container {
  padding-right:-300px;
  
}
.carousel-item{
  max-width: 464px;
  min-width:300px;
}
.center_img img{
  display: block;
  /* margin-top: 13px; */
  width: 80%;
  margin: 13px auto;
}

.slide_part .right_slide a{
  color:#007185;
  text-decoration: none;
  text-transform: capitalize;
}

.slide_part .right_slide img{
  height:275px;
  width:90%;
}

.Card_section .Card_deal{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding: 20px;
}

.Card_section .Card_deal h3{
  color: #444;
  font-weight: 500;
  font-size: 19px;
}

.view_btn{
  padding: 9px 15px;
  border: none;
  outline: none;
  background-color: #2874f0;
  color: #fff;
  width: 100px;
  letter-spacing: 1px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}


.Card_section a{
  text-decoration: none;
  color: #111;
}


.Card_section .Card_items img{
  width: 300px;
  height: 350px;
  margin-top: 4px;
}
.Card{
  background-color: #444;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.Card_section .Card_items{
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.Card_name {
  color: #fcfcfceb;
  font-size: 20px;
  font-weight: 400;
}
.Card_explore{
  color: white;
}





.Card_section .Card_items p{
  margin-top: 2px;
}


@media (max-width:768px){
  .slide_part .right_slide{
   display: none;
  }
  
  .slide_part .left_slide{
      width: 100%;
  }

  .center_img img{
      object-fit: cover;
      height: 120px;
  }
  .Card_section .Card_items img{
    width: 200px;
    height: 250px;
    margin-top: 4px;
  }
  .Card_name {
    color: #fcfcfceb;
    font-size: 20px;
    font-weight: 40;
  }
  .Card_explore{
    color: white;
    font-size: 15px;
    font-weight: 20;
  }

}
